import React from 'react'
import { animated } from 'react-spring'

import { _Image } from 'types/types'

const s = {
  container: `w-full h-full smx:order-last 3xsx:h-[35rem] 4xsx:h-[32rem]`,
  image: `-w-full h-full object-cover`,
}

const Image = ({ src, title, style }: _Image) => {
  return (
    <animated.div className={s.container} style={style}>
      <img className={s.image} src={src} alt={title} loading="eager" />
    </animated.div>
  )
}

export default Image
