import React from 'react'

import { _Gallery } from 'types/types'

import { useModalContext } from 'hooks/modal-context'

import Modal from 'shared/Modal'
import Slider from 'shared/Slider'

const s = {
  group: `w-full h-full`,
  title: `absolute top-4 right-4 z-[2] mb-0 text-2xl text-white uppercase`,
  count: `absolute top-12 right-4 z-[2] mb-0 text-base text-white`,
  image: `w-full h-full object-cover`,
}

const Image = ({ photos }: _Gallery) => {
  const { openModal } = useModalContext()

  return (
    <Modal openModal={openModal}>
      <Slider show={true}>
        {photos.map(({ image }, idx) => (
          <div key={idx} className={s.group}>
            <img
              className={s.image}
              src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
              alt={image.title}
            />

            <p className={s.title}>{image.title}</p>
            <p className={s.count}>{`${idx + 1} of ${photos.length}`}</p>
          </div>
        ))}
      </Slider>
    </Modal>
  )
}

export default Image
