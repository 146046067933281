import React from 'react'
import { Link } from 'gatsby'

import { _Post } from 'types/types'

import { dateParse } from 'libs/dateParse'

import { useAnimation } from 'main/Post/Animation'
import { LinkButton } from 'shared/Button'

const s = {
  container: `w-full h-fit`,
  flex: `w-full h-full flex`,

  card: `relative w-full h-fit px-2`,
  link: `w-full h-full`,
  image: `w-full h-[55rem] object-cover lgx:h-[45rem] mdx:h-[40rem] smx:h-[30rem] 
  xsx:h-[45rem] 2xsx:h-[35rem] 3xsx:h-[35rem] 4xsx:h-[32rem]`,

  body: `w-full h-fit flex flex-col pt-12 px-4`,
  title: `mb-4 leading-[1.05] underline underlinewhite transition-all delay-150 ease-in duration-150 
  hover:underlinered smx:text-[2.5rem]`,
  date: `mb-12 text-sm`,

  lastcard: `mdx:hidden 3xsx:block`,
}

const Content = ({ posts, trail }: _Post) => {
  const { Trails } = useAnimation()

  return (
    <div className={s.container}>
      <Trails classname={s.flex} trail={trail}>
        {posts.map(({ _id, slug, title, date, image }, idx) => {
          const cards = [idx === posts.length - 1 && s.lastcard]

          return (
            <div key={_id} className={`${cards} ${s.card}`}>
              <Link className={s.link} to={`/post/${slug}`} aria-label={title}>
                <img
                  className={s.image}
                  src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
                  alt={image.title}
                />
              </Link>
              <div className={s.body}>
                <Link
                  className={s.link}
                  to={`/post/${slug}`}
                  aria-label={title}
                >
                  <h3 className={s.title}>{title}</h3>
                </Link>
                <p className={s.date}>{dateParse(date)}</p>

                <LinkButton classname="block ml-auto" to={`/post/${slug}`}>
                  Read News
                </LinkButton>
              </div>
            </div>
          )
        })}
      </Trails>
    </div>
  )
}

export default Content
