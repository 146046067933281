import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas } from 'types/types'

import { useAnimation } from 'main/Hero/Animation'

import Image from 'main/Hero/Image'
import Content from 'main/Hero/Content'

const s = {
  container: `relative w-full h-screen overflow-hidden`,
  wrapper: `wrapper flex flex-col absolute top-0 left-1/2 -translate-x-1/2 z-2`,
  grid: `grid grid-cols-3 grid-rows-1`,
  block: `absolute z-[10] w-full h-full bg-black`,
}

const Hero = () => {
  const { takeshape } = useStaticQuery(
    graphql`
      query {
        takeshape {
          hero: getHero {
            slug
            title
            subtitle
            link
            text
            image {
              title
              path
            }
          }
        }
      }
    `
  )

  const { fadeInImage, trailText, onAnimate } = useAnimation()

  const { slug, title, subtitle, link, text, image }: _Datas = takeshape.hero

  return (
    <section className={s.container} id={slug}>
      <InView onChange={onAnimate}>
        <Image
          src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
          title={image.title}
          style={fadeInImage}
        />

        <div className={s.wrapper}>
          <Content
            title={title}
            subtitle={subtitle}
            link={link}
            text={text}
            trail={trailText}
          />
        </div>
      </InView>
    </section>
  )
}

export default Hero
