import { useState } from 'react'
import {
  animated,
  useChain,
  useSpring,
  useSpringRef,
  easings,
} from 'react-spring'

export const useAnimation = () => {
  const [animate, setAnimate] = useState(false)

  const imageApi = useSpringRef()
  const contentApi = useSpringRef()

  const onAnimate = (inView: boolean) => {
    if (!animate && inView) setAnimate(true)
  }

  const fadeInTitle = useSpring({
    to: { opacity: animate ? 1 : 0 },
    delay: 50, //50
    config: {
      easing: easings.easeInOutQuint,
      duration: 600, //800
    },
    ref: contentApi,
  })

  const fadeInContent = useSpring({
    to: { opacity: animate ? 1 : 0 },
    delay: 200, //200
    config: {
      easing: easings.easeInOutQuint,
      duration: 600, //800
    },
    ref: imageApi,
  })

  useChain(animate ? [contentApi, imageApi] : [], animate ? [0.2, 0.4] : [0])

  return {
    onAnimate,
    animated,
    fadeInContent,
    fadeInTitle,
  }
}
