import React from 'react'

import { _Image } from 'types/types'

import { useAnimation } from 'main/Hero/Animation'

const s = {
  container: `absolute top-0 z-[-1] w-full h-full`,
  image: `w-full h-screen text-transparent transition delay-300 ease-in xln:object-fill mdx:object-fill 
  smx:h-[calc(100vh_+_20rem)] xsx:object-cover xsx:h-[92.5rem] 2xsx:h-[calc(100vh_+_25rem)] 2xsx:object-fill 3xsx:h-screen 3xsx:object-cover !important`,
}

const Image = ({ src, title, style }: _Image) => {
  const { animated } = useAnimation()

  return (
    <animated.div className={s.container} style={style}>
      <img className={s.image} src={src} alt={title} />
    </animated.div>
  )
}

export default Image
