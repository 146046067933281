import React from 'react'

import { _Trip } from 'types/types'

import { useAnimation } from 'main/Trips/Animation'

const s = {
  flex: `w-full h-full flex`,

  card: `flex flex-col px-2`,
  image: `w-full h-[40rem] object-cover lgx:h-[35rem] mdx:h-[29rem] smx:h-[30rem] 
  xsx:h-[21.5rem] 2xsx:h-[28rem] 3xsx:h-[35rem] 4xsx:h-[32rem]`,

  list: `flex flex-col bg-lightgray`,
  items: `py-6 flex flex-col justify-center items-center text-center 
  border-b-[2px] border-solid border-white last:border-0 smx:py-5`,

  title: `mb-4 text-3xl leading-[1.05] smx:text-[2.65rem] smx:w-5/6`,
  subtitle: `text-xl font-sub`,
  description: `text-lg text-darkgray`,

  block0: `smx:my-0`,
}

const Content = ({ trips, trail }: _Trip) => {
  const { Trails } = useAnimation()

  return (
    <Trails classname={s.flex} trail={trail}>
      {trips.map(
        ({ _id, title, name, city, days, groups, difficulty, image }, idx) => {
          const cards = [idx === trips.length - 1 && s.block0]

          return (
            <div className={`${cards} ${s.card}`} key={_id}>
              <img
                className={s.image}
                src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
                alt={image.title}
              />

              <ul className={s.list}>
                <li className={s.items}>
                  <h3 className={s.title}>{title}</h3>
                  <p className={s.description}>{name}</p>
                  <p className={s.description}>{city}</p>
                </li>
                <li className={s.items}>
                  <h3 className={s.subtitle}>Days</h3>
                  <p className={s.description}>{days}</p>
                </li>
                <li className={s.items}>
                  <h3 className={s.subtitle}>Groups</h3>
                  <p className={s.description}>{groups}</p>
                </li>
                <li className={s.items}>
                  <h3 className={s.subtitle}>Difficulty</h3>
                  <p className={s.description}>{difficulty}</p>
                </li>
              </ul>
            </div>
          )
        }
      )}
    </Trails>
  )
}

export default Content
