import React from 'react'

import { useModalContext } from 'hooks/modal-context'

type _Modal = {
  openModal: boolean
  children: any
}

const s = {
  container: (open: boolean) => `
  fixed top-0 left-0 z-[99] w-full h-full flexcenter bg-darkblack  
  ${open ? `opacity-100 visible` : `opacity-0 invisible`}
  transition-all delay-300 ease-out duration-500`,

  group: `w-11/12 h-[90vh] px-4 pb-12 rounded bg-transparent`,
  header: `w-full h-fit flexbetween py-4 `,
  button: `my-0 mr-2 ml-auto text-lg border-0 bg-transparent text-white curser-pointer`,

  body: `w-full h-[83vh] smx:h-[75vh] xsx:h-[73vh] 2xsx:h-[72vh] 3xsx:h-[82vh] 4xsx:h-[80vh]`,
}

const Modal = ({ openModal, children }: _Modal) => {
  const { setOpenModal } = useModalContext()

  const closeClick = () => setOpenModal(false)

  return (
    <section className={s.container(openModal)}>
      <div className={s.group}>
        <div className={s.header}>
          <button className={s.button} onClick={() => closeClick()}>
            Close
          </button>
        </div>
        <div className={`${s.body}`}>{children}</div>
      </div>
    </section>
  )
}

export default Modal
