import React, { useState } from 'react'
import { animated } from 'react-spring'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'

import { _Content, _Form } from 'types/types'

import { s } from 'main/Contact/_contactform'

import Checkbox from 'main/Contact/Checkbox'
import { FormButton } from 'shared/Button'

const Form = ({ title, subtitle, style }: _Content) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    formState,
  } = useForm<_Form>({
    mode: 'onChange',
  })

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    checkbox: false,
  })

  const { name, email, phone, message, checkbox } = values

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    clearErrors(['name', 'email', 'phone', 'message', 'checkbox'])
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault()

    const form = e.target

    axios({
      method: 'post',
      url: process.env.GATSBY_FORM_URL,
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset()

        toast.success(`Thanks for reaching out. I'll get back to you soon.`)

        setValues({
          ...values,
          name: '',
          email: '',
          phone: '',
          message: '',
          checkbox: false,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          'Oops, something went wrong. The form could not be submitted.'
        )
        setValues({ ...values, checkbox: false })
      })
  })

  return (
    <animated.div className="w-full h-fit" style={style}>
      <Toaster position="top-center" reverseOrder={false} />

      <h2 className={s.title}>{title}</h2>
      <p className={s.subtitle}>{subtitle}</p>

      <form name="contact" onSubmit={onSubmit} className={s.form}>
        <div className={s.maingroup}>
          <div className={s.subgroup}>
            <label htmlFor="name" className={`${s.label} form-label`}>
              Name
            </label>
            <p className={s.count}>{`${name.length}/20`}</p>
          </div>
          <input
            {...register('name', {
              required: true,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            className={s.input}
            id="name"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={handleChange}
          />
          {errors.name && (
            <p className={s.error}>
              Please fill in a name with between 3-20 characters long.
            </p>
          )}
        </div>

        <div className={s.maingroup}>
          <label htmlFor="email" className={`${s.label} form-label`}>
            Email
          </label>
          <input
            {...register('email', {
              required: true,
              validate: (input: any) => isEmail(input),
              minLength: 6,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
            })}
            className={s.input}
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className={s.error}>Please enter a valid email.</p>
          )}
        </div>

        <div className={s.maingroup}>
          <label htmlFor="phone" className={`${s.label} form-label`}>
            Phone (optional)
          </label>
          <input
            {...register('phone', {
              required: false,
              minLength: 10,
            })}
            className={s.input}
            id="phone"
            type="tel"
            name="phone"
            placeholder="Phone(10)"
            value={phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <p className={s.error}>Please enter a valid phone number.</p>
          )}
        </div>

        <div className={s.maingroup}>
          <div className={s.subgroup}>
            <label htmlFor="message" className={`${s.label} form-label`}>
              Message
            </label>
            <p className={s.count}>{`${message.length}/300`}</p>
          </div>
          <textarea
            {...register('message', {
              required: true,
              maxLength: 300,
            })}
            className={s.message}
            id="message"
            name="message"
            placeholder="Message"
            value={message}
            onChange={handleChange}
          />
          {errors.message && (
            <p className={s.error}>
              Please fill in a comment with max 300 characters long.
            </p>
          )}
        </div>

        <Checkbox
          register={register}
          handleChange={handleChange}
          errors={errors}
        />

        <FormButton disabled={formState.isSubmitting}>Send</FormButton>
      </form>
    </animated.div>
  )
}

export default Form
