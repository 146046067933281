import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SmoothButton } from 'shared/Button'

interface _Datas {
  title: string
  link: string
  text: string
  image: {
    title: string
    path: any
  }
}

const s = {
  container: `w-fit h-fit`,
  button: `block border-none bg-transparent `,
  image: `w-[2.5rem] h-[2.5rem] animate-bounce`,
}

const Down = () => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        down: getDown {
          title
          link
          text
          image {
            title
            path
          }
        }
      }
    }
  `)

  const { link, text, image }: _Datas = takeshape.down

  return (
    <div className={s.container}>
      <SmoothButton classname={s.button} to={link} aria-label={text}>
        <img
          className={s.image}
          src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=120&w=120&fm=webp`}
          alt={image.title}
          title="About Somi"
        />
      </SmoothButton>
    </div>
  )
}

export default Down
