import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas, _Galleries, _Image } from 'types/types'

import { useAnimation } from 'main/Gallery/Animation'
import Content from 'main/Gallery/Content'
import Image from 'main/Gallery/Image'

const s = {
  container: `relative w-full h-fit section`,
  wrapper: `wrapper`,

  group: `absolute top-[6rem] z-[1] w-fit h-fit p-12 bg-lightgreen 
  3xsx:w-[37.5rem] 3xsx:p-8 4xsx:w-[32.5rem]`,
  title: `-mt-4 -mb-2 leading-[1.5] text-white`,
  subtitle: `text-2xl leading-[1.25] font-bold font-main text-white`,
}

const Gallery = () => {
  const { animated, fadeInTitle, trailImage, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        gallery: getGallery {
          slug
          title
          subtitle
        }
        galleries: getGalleriesList {
          items {
            image {
              title
              path
            }
          }
        }
      }
    }
  `)

  const { slug, title, subtitle }: _Datas = takeshape.gallery

  const photos: _Galleries[] = takeshape.galleries.items

  return (
    <section className={s.container} id={slug}>
      <InView as="div" rootMargin="-50px" onChange={onAnimate}>
        <div className={s.wrapper}>
          <animated.div className={s.group} style={fadeInTitle}>
            <h2 className={s.title}>{title}</h2>
            <p className={s.subtitle}>{subtitle}</p>
          </animated.div>
        </div>
        <Content photos={photos} trail={trailImage} />
        <Image photos={photos} />
      </InView>
    </section>
  )
}

export default Gallery
