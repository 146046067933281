import React, { useState } from 'react'
import {
  animated,
  useTrail,
  useSpring,
  useSpringRef,
  useChain,
  easings,
} from 'react-spring'
import Carousel from 'react-multi-carousel'

import 'react-multi-carousel/lib/styles.css'

import { _Trail } from 'types/types'

export const Trails: React.FC<_Trail> = ({ classname, trail, children }) => {
  const items = React.Children.toArray(children)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <Carousel
      className={classname}
      containerClass="carousel-container"
      responsive={responsive}
      infinite={true}
    >
      {trail.map(({ ...style }: any, index: number) => (
        <animated.div key={index} style={style}>
          {items[index]}
        </animated.div>
      ))}
    </Carousel>
  )
}

export const useAnimation = () => {
  const [animate, setAnimate] = useState(false)

  const imageApi = useSpringRef()
  const contentApi = useSpringRef()

  const onAnimate = (inView: boolean) => {
    if (!animate && inView) setAnimate(true)
  }

  const fadeInTitle = useSpring({
    to: { opacity: animate ? 1 : 0 },
    delay: 50, //50
    config: {
      easing: easings.easeInOutQuint,
      duration: 600, //800
    },
    ref: contentApi,
  })

  const trailContent = useTrail(3, {
    to: { opacity: animate ? 1 : 0 },
    delay: 300, //300
    config: {
      easing: easings.easeOutQuad,
      duration: 800, //600
    },
    ref: imageApi,
  })

  useChain(animate ? [contentApi, imageApi] : [], animate ? [0.2, 0.4] : [0])

  return {
    onAnimate,
    animated,
    Trails,
    trailContent,
    fadeInTitle,
  }
}
