export const s = {
  // FORM
  form: `w-full`,
  title: `-mt-4 -mb-2 leading-[1.5]`,
  subtitle: `w-[32rem] mb-12 text-2xl leading-[1.25] font-bold font-main`,

  flex: `w-full flex justify-between lgx:flex-col`,
  maingroup: `flex flex-col mb-8`,

  subgroup: `flex justify-between items-center`,
  label: `mb-4 text-2xl font-bold text-black`,
  count: `text-xs text-darkgray`,

  input: `p-4 text-lg font-inherit rounded border-[1px] border-solid border-gray bg-gray text-darkgray focus:outline-none`,
  select: `p-4 text-lg font-inherit border-[1px] border-solid border-gray bg-gray text-darkgray focus:outline-none`,
  option: `text-lg font-inherit bg-gray text-darkgray border-[1px] border-solid border-gray hover:text-red`,
  message: `h-[15rem] resize-y p-4 text-lg font-inherit rounded border border-solid border-gray bg-gray text-darkgray focus:outline-none`,

  // CHECK BOX
  checkbox: `relative w-fit-content mb-20`,
  checklabel: `flex align-center mb-0 text-lg checklabel`,
  checkspan: `block checkspan cursor-pointer`,
  checkinput: `w-[2.5rem] h-[2.5rem] mr-4 appearance-none bg-gray border-[1px] border-solid border-gray rounded outline-none transition delay-300 ease-in curser-pointer checkinput checked:bg-gray checked:border-[1px] checked:border-solid checked:border-gray active:border-[1px] active:border-solid active:border-darkgray`,

  // MESSAGE WARNING
  error: `mt-3 text-red`,
}
