import React, { useState } from 'react'
import {
  animated,
  useSpring,
  useTrail,
  useChain,
  useSpringRef,
  easings,
} from 'react-spring'

import { _Trail } from 'types/types'

export const Trails: React.FC<_Trail> = ({ classname, trail, children }) => {
  const items = React.Children.toArray(children)

  return (
    <div className={classname}>
      {trail.map(({ ...style }: any, index: number) => (
        <animated.div key={index} style={style}>
          {items[index]}
        </animated.div>
      ))}
    </div>
  )
}

export const useAnimation = () => {
  const [animate, setAnimate] = useState(false)

  const imageApi = useSpringRef()
  const textApi = useSpringRef()

  const onAnimate = (inView: boolean) => {
    if (!animate && inView) setAnimate(true)
  }

  const fadeInImage = useSpring({
    to: { opacity: animate ? 1 : 0 },
    delay: 200, //100
    config: {
      easing: easings.easeInOutQuint,
      duration: 800, //800
    },
    ref: imageApi,
  })

  const trailText = useTrail(3, {
    to: { opacity: animate ? 1 : 0, y: animate ? 0 : 100 },
    delay: 1200, //1600
    config: {
      easing: easings.easeOutQuad,
      duration: 800, //800
    },
    ref: textApi,
  })

  useChain(animate ? [imageApi, textApi] : [], [0.1, 0.3])

  return {
    onAnimate,
    Trails,
    animated,
    fadeInImage,
    trailText,
  }
}
