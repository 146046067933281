import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Seo from 'shared/Seo'
import Layout from 'shared/Layout'
import Hero from 'main/Hero'
import Services from 'main/Services'
import About from 'main/About'
import Trips from 'main/Trips'
import Gallery from 'main/Gallery'
import Post from 'main/Post'
import Contact from 'main/Contact'

const IndexPage = () => {
  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        seo: getSeo {
          title
          description
        }
      }
    }
  `)

  const { title, description } = takeshape.seo

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Hero />
      <Services />
      <About />
      <Trips />
      <Gallery />
      <Post />
      <Contact />
    </Layout>
  )
}

export default IndexPage
