import React from 'react'
import { animated } from 'react-spring'
import Markdown from 'markdown-to-jsx'

import { _Content, $Content } from 'types/types'

import Social from 'shared/Social'

const s = {
  content: `w-full h-fit smx:order-first`,
  title: `-mt-4 -mb-2 leading-[1.5]`,
  subtitle: `w-[32rem] mb-12 text-2xl leading-[1.25] font-bold font-main`,
  description: `-mt-4 mb-20 text-base text-justify leading-loose about`,
}

const Content = ({ title, subtitle, description, style }: $Content) => {
  return (
    <animated.div className={s.content} style={style}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.subtitle}>{subtitle}</p>
      <Markdown className={s.description}>{description}</Markdown>
      <Social filters={true} direction="flex-row" />
    </animated.div>
  )
}

export default Content
