import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas, $Datas } from 'types/types'

import { useAnimation } from 'main/About/Animation'
import Content from 'main/About/Content'
import Image from 'main/About/Image'

const s = {
  container: `relative w-full h-fit section smx:mt-24 smx:py-24 3xsx:pb-0`,
  wrapper: `wrapper h-full`,
  grid: `grid-2 items-center smx:grid-cols-1 3xsx:items-end`,
  main: `w-full h-full gap-40 mdx:gap-32 smx:gap-20 3xsx:gap-4`,
  sub: `absolute top-0 z-[-1] w-full h-full smx:gap-20 xsx:gap-0`,
  box: `w-1/2 h-screen smx:w-full smx:h-[44rem] xsx:h-[44rem] 2xsx:h-[43rem] 3xsx:h-[40rem] 4xsx:h-[37rem]`,
}

const About = () => {
  const { fadeInContent, fadeInImage, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        about: getAbout {
          slug
          title
          subtitle
          description
          image {
            title
            path
          }
        }
      }
    }
  `)

  const { slug, title, subtitle, description, image }: $Datas = takeshape.about

  return (
    <section className={s.container} id={slug}>
      <InView
        className="h-full"
        as="div"
        rootMargin="-50px"
        onChange={onAnimate}
      >
        <div className={s.wrapper}>
          <div className={`${s.grid} ${s.main}`}>
            <div className={s.box} />
            <Content
              title={title}
              subtitle={subtitle}
              description={description}
              style={fadeInContent}
            />
          </div>
        </div>

        <div className={`${s.grid} ${s.sub}`}>
          <Image
            src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
            title={image.title}
            style={fadeInImage}
          />

          <div className={s.box} />
        </div>
      </InView>
    </section>
  )
}

export default About
