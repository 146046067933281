import React from 'react'

import { _Gallery } from 'types/types'

import { useModalContext } from 'hooks/modal-context'

import { useAnimation } from 'main/Gallery/Animation'

const s = {
  grid: `h-screen grid grid-cols-4 grid-rows-4 grid-flow-dense 
  mdx:h-fit mdx:grid-cols-2 mdx:grid-rows-auto 3xsx:grid-cols-1`,
  link: `w-full h-full text-center cursor-pointer 
  mdx:h-[51rem] smx:h-[44rem] xsx:h-[38rem] 2xsx:h-[32rem] 3xsx:h-[41rem] 4xsx:h-[36rem]`,
  image: `w-full h-full object-cover transition-all delay-300 ease-out`,
  text: `w-full h-[50rem] flex justify-center items-center 2xsx:h-[32rem]`,

  // NTH CHILD IMAGE GRID
  link0: `row-span-2`,
  link1: `row-span-2`,
  link2: `row-span-4 col-span-2 mdx:row-span-2 mdx:col-span-1`,
  link3: `row-span-2`,
  link4: `row-span-2 mdx:hidden 3xsx:block`,
}

const Content = ({ photos, trail }: _Gallery) => {
  const { setOpenModal, setIndex } = useModalContext()
  const { animated } = useAnimation()

  const openClick = (id: number) => {
    setIndex(id)
    setOpenModal(true)
  }

  const images = photos.map(({ _id, image }, idx) => (
    <div key={_id} className={s.link} onClick={() => openClick(idx)}>
      <img
        className={s.image}
        src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
        alt={image.title}
      />
    </div>
  ))

  const items = React.Children.toArray(images)

  return (
    <div className={s.grid}>
      {trail.map(({ ...style }: any, idx: number) => {
        const links = [
          idx === 0 && s.link0,
          idx === 1 && s.link1,
          idx === 2 && s.link2,
          idx === 3 && s.link3,
          idx === 4 && s.link4,
        ].join(' ')

        return (
          <animated.div className={links} key={idx} style={style}>
            {items[idx]}
          </animated.div>
        )
      })}
    </div>
  )
}

export default Content
