import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Data, _Trips } from 'types/types'

import { useAnimation } from 'main/Trips/Animation'
import Content from 'main/Trips/Content'

const s = {
  container: `w-full min-h-screen my-24 py-24 section smx:min-h-0 smx:h-fit`,
  wrapper: `wrapper`,

  group: `w-full`,
  title: `-mt-2 mb-4 leading-none`,
  subtitle: `w-[32rem] mb-16 text-2xl leading-[1.25] font-bold font-main`,
}

const Trips = () => {
  const { animated, fadeInTitle, trailImage, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        trip: getTrip {
          slug
          title
          subtitle
        }
        trips: getTripsList(size: 4) {
          items {
            _id
            slug
            title
            name
            city
            days
            groups
            difficulty
            image {
              title
              path
            }
          }
        }
      }
    }
  `)

  const { slug, title, subtitle }: _Data = takeshape.trip
  const trips: _Trips[] = takeshape.trips.items

  return (
    <section className={s.container} id={slug}>
      <InView as="div" rootMargin="-50px" onChange={onAnimate}>
        <div className={s.wrapper}>
          <animated.div className={s.group} style={fadeInTitle}>
            <h2 className={s.title}>{title}</h2>
            <p className={s.subtitle}>{subtitle}</p>
          </animated.div>
          <Content trips={trips} trail={trailImage} />
        </div>
      </InView>
    </section>
  )
}

export default Trips
