import React, { useState, useEffect } from 'react'
import { animated, easings, useTransition } from 'react-spring'

import { useModalContext } from 'hooks/modal-context'

type _Datas = {
  show: boolean
  autoPlay?: boolean
  children: React.ReactNode
}

const s = {
  container: `relative w-full h-full overflow-hidden`,
  carousel: `absolute top-0 left-0 w-full h-full`,
  button: `absolute top-1/2 z-10 text-2xl text-gray bg-0 border-0 cursor-pointer`,
  prev: `absolute left-8`,
  next: `absolute right-8`,
}

const Slider = ({ show, autoPlay, children }: _Datas) => {
  const { index, setIndex } = useModalContext()
  const [id, setId] = useState(0)
  const [reverse, setReverse] = useState(false)

  const isIndex = show ? index : id

  const items = React.Children.toArray(children)

  useEffect(() => {
    let timer = setTimeout(() => {
      autoPlay ? (id < items.length - 1 ? setId(id + 1) : setId(0)) : []
    }, 7000)

    return () => clearTimeout(timer)
  }, [index, items])

  const transitions = useTransition(isIndex, {
    from: {
      key: isIndex,
      opacity: 0,
      transform: `translate3d(${reverse ? `100%` : `-100%`}, 0, 0)`,
      delay: 300, //300
      config: {
        easing: easings.easeInOutCubic,
        duration: 800, //800
      },
      exitBeforeEnter: true,
    },
    enter: { opacity: 1, transform: `translate3d(0, 0, 0)` },
    leave: {
      opacity: 0,
      transform: `translate3d(${reverse ? `100%` : `-100%`}, 0, 0)`,
    },
  })

  const prev = () => {
    let prev = isIndex - 1 < 0 ? items.length - 1 : isIndex - 1
    show ? setIndex(prev) : setId(prev)
    setReverse(true)
  }

  const next = () => {
    let next = isIndex + 1 < items.length ? isIndex + 1 : 0
    show ? setIndex(next) : setId(next)
    setReverse(false)
  }

  return (
    <div className={s.container}>
      <div>
        {transitions((style, i) => (
          <animated.div className={s.carousel} style={{ ...style }}>
            {items[i]}
          </animated.div>
        ))}
      </div>

      <div className={`${s.button} ${s.prev}`} onClick={prev}>
        ◀︎
      </div>

      <div className={`${s.button} ${s.next}`} onClick={next}>
        ▶︎
      </div>
    </div>
  )
}

export default Slider
