import React from 'react'

import { _Content } from 'types/types'

import { useAnimation } from 'main/Hero/Animation'
import Down from 'shared/Down'

const s = {
  content: `w-[72rem] h-full flexcenter flex-col overflow-hidden
  smx:w-[71rem] smx:mt-20 xsx:mt-0 xsx:w-[55rem] 2xsx:mt-16 2xsx:w-full`,
  group: `w-full h-fit`,
  title: `-mt-4 mb-4 leading-[1.05] 3xsx:text-[6rem] 4xsx:text-[5rem]`,
  subtitle: `mb-24 text-clamp-3xl xsx:mb-16 4xsx:w-2/3`,
}

const Content = ({ title, subtitle, trail }: _Content) => {
  const { Trails } = useAnimation()

  return (
    <div className={s.content}>
      <Trails classname={s.group} trail={trail}>
        <h1 className={s.title}>{title}</h1>
        <p className={s.subtitle}>{subtitle}</p>

        <Down />
      </Trails>
    </div>
  )
}

export default Content
