import React from 'react'

import { _Image } from 'types/types'

import { useAnimation } from 'main/Hero/Animation'

const s = {
  container: `w-full h-full`,
  image: `w-full h-screen object-cover text-transparent transition delay-300 ease-in 
  xln:object-fill mdx:object-fill xsx:object-cover 3xsx:object-cover !important`,
}

const Image = ({ src, title, style }: _Image) => {
  const { animated } = useAnimation()

  return (
    <animated.div className={s.container} style={style}>
      <img className={s.image} src={src} alt={title} />
    </animated.div>
  )
}

export default Image
