import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas, _Posts } from 'types/types'

import { useAnimation } from 'main/Post/Animation'
import Content from 'main/Post/Content'

import { LinkButton } from 'shared/Button'

const s = {
  container: `w-full min-h-screen my-24 py-24 section smx:min-h-0 smx:h-fit`,
  wrapper: `wrapper`,

  flex: `w-full flexbetween items-end mb-20 xsx:flex-col`,
  group: `w-fit xsx:w-full xsx:mb-12 `,
  title: `-mt-4 mb-4 leading-none`,
  subtitle: `w-[32rem] mb-0 text-2xl leading-[1.25] font-bold font-main`,

  button: `ml-auto`,
}

const Post = () => {
  const { animated, fadeInTitle, trailContent, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        news: getNews {
          slug
          title
          subtitle
        }
        posts: getPostsList(size: 4, sort: { field: "date", order: "DESC" }) {
          items {
            _id
            slug
            title
            date
            image {
              title
              path
            }
          }
        }
      }
    }
  `)

  const { slug, title, subtitle }: _Datas = takeshape.news
  const posts: _Posts[] = takeshape.posts.items

  return (
    <section className={s.container} id={slug}>
      <InView as="div" rootMargin="-50px" onChange={onAnimate}>
        <div className={s.wrapper}>
          <animated.div className={s.flex} style={fadeInTitle}>
            <div className={s.group}>
              <h2 className={s.title}>{title}</h2>
              <p className={s.subtitle}>{subtitle}</p>
            </div>
            <LinkButton classname={s.button} to={`/posts/`}>
              All News
            </LinkButton>
          </animated.div>
          <Content posts={posts} trail={trailContent} />
        </div>
      </InView>
    </section>
  )
}

export default Post
