import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas, _Services } from 'types/types'

import { useAnimation } from 'main/Services/Animation'
import Content from 'main/Services/Content'
import Image from 'main/Services/Image'

const s = {
  container: `relative w-full h-screen flex flex-col justify-center section smx:h-fit smx:py-24`,
  wrapper: `h-full wrapper`,

  group: `w-full mb-24 p-12 text-center bg-lightgreen mdx:mb-16 2xsx:hidden 3xsx:block 3xsx:mb-8 3xsx:p-7 4xsx:hidden`,
  title: `-mt-4 leading-[1.5] text-white`,
  subtitle: `w-[32rem] mx-auto leading-[1.25] font-bold font-main text-2xl text-white`,
}

const Services = () => {
  const { animated, fadeInTitle, fadeInContent, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        service: getService {
          slug
          title
          subtitle
          image {
            path
            title
          }
        }
        services: getServicesList {
          items {
            _id
            title
            description
          }
        }
      }
    }
  `)

  const { slug, title, subtitle, image }: _Datas = takeshape.service
  const services: _Services[] = takeshape.services.items

  return (
    <section className={s.container} id={slug}>
      <Image
        src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
        title={image.title}
      />
      <InView as="div" rootMargin="-50px" onChange={onAnimate}>
        <div className={s.wrapper}>
          <animated.div className={s.group} style={fadeInTitle}>
            <h2 className={s.title}>{title}</h2>
            <p className={s.subtitle}>{subtitle}</p>
          </animated.div>
          <Content services={services} style={fadeInContent} />
        </div>
      </InView>
    </section>
  )
}

export default Services
