import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { _Datas } from 'types/types'

import { useAnimation } from 'main/Contact/Animation'
import Form from 'main/Contact/Form'
import Image from 'main/Contact/Image'

const s = {
  container: `relative w-full h-fit section smx:py-24 smx:my-24`,
  wrapper: `wrapper`,
  grid: `grid-2 items-center smx:grid-cols-1 3xsx:items-start`,
  main: `w-full h-full gap-40 xlx:py-40 mdx:gap-32 smx:py-0 smx:gap-20 3xsx:gap-4`,
  sub: `absolute top-0 z-[-1] w-full h-full smx:gap-20 xsx:gap-0`,
  box: `w-1/2 h-screen smx:w-full smx:h-[64rem] xsx:h-[63rem] 2xsx:h-[59rem] 3xsx:h-[35rem] 4xsx:h-[31rem] `,
}

const Contact = () => {
  const { fadeInContent, fadeInInfo, onAnimate } = useAnimation()

  const { takeshape } = useStaticQuery(graphql`
    query {
      takeshape {
        contact: getContact {
          slug
          title
          subtitle
          image {
            title
            path
          }
        }
      }
    }
  `)

  const { slug, title, subtitle, image }: _Datas = takeshape.contact

  return (
    <section className={s.container} id={slug}>
      <InView as="div" rootMargin="-50px" onChange={onAnimate}>
        <div className={s.wrapper}>
          <div className={`${s.grid} ${s.main}`}>
            <div className={s.box} />
            <Form title={title} subtitle={subtitle} style={fadeInContent} />
          </div>
        </div>

        <div className={`${s.grid} ${s.sub}`}>
          <Image
            src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
            title={image.title}
            style={fadeInInfo}
          />
          <div className={s.box} />
        </div>
      </InView>
    </section>
  )
}

export default Contact
