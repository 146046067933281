import React from 'react'
import { animated } from 'react-spring'

import { _Service } from 'types/types'

const s = {
  container: `w-full h-fit 4xsx:-mt-[8.5rem]`,
  grid: `grid-3 gap-24 mdx:gap-16 xsx:grid-1 2xsx:gap-8`,
  card: `w-full h-[32rem] py-12 px-24 flex flex-col items-center justify-center text-center bg-lightgreen
  mdx:h-[28rem] mdx:px-12 smx:h-[30rem] xsx:h-fit 2xsx:p-8`,
  title: `mb-6 text-white 2xsx:mb-4 4xsx:text-3xl`,
  description: `text-base text-white leading-[1.5] 4xsx:text-sm`,
}

const Content = ({ services, style }: _Service) => {
  const service = services.map(({ _id, title, description }) => (
    <animated.div className={s.card} key={_id} style={style}>
      <h3 className={s.title}>{title}</h3>
      <p className={s.description}>{description}</p>
    </animated.div>
  ))

  return (
    <div className={s.container}>
      <div className={s.grid}>{service}</div>
    </div>
  )
}

export default Content
